import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isLogin, logOut } from '../auth/AuthController';
import '../../assets/css/header.css';
import '../../assets/css/notification.css';
import menuIcon from '../../assets/images/icons/menu-cons.png';
import logo from '../../assets/images/logo.png';
import gameIcon from '../../assets/images/icons/Game.svg';
import starIcon from '../../assets/images/icons/Star.svg';
import tournamentIcon from '../../assets/images/icons/bi_trophy-fill.svg';
import leaderboardIcon from '../../assets/images/icons/leaderboard.png';
import youtubeIcon from '../../assets/images/icons/youtube.png';
import completeTaskIcon from '../../assets/images/icons/Tick Square.svg';
import logoutIcon from '../../assets/images/icons/logout.svg';
import notificationIcon from '../../assets/images/icons/Notification.png';
import profileImg from '../../assets/images/profile/profile-img.png';
import homeIcon from '../../assets/images/icons/home.svg';
import crossIcon from '../../assets/images/icons/cross-icon.png';
import editIcon from '../../assets/images/icons/edit-icon.png';
import deleteImg from '../../assets/images/icons/Delete.png';
import bellimg from '../../assets/images/icons/subscribed.png';
import unSubscribed from '../../assets/images/icons/un-subscribed.png';
import shouldRenderYouTubeVideos from '../helpers/shouldRender';
import { requestGetHTTP, requestPostHTTP } from "../helpers/helper";
import { getLoginUrl } from '../helpers/urlHelper';

function Header(props) {
  const location = useLocation();
  const hideHeaderRoutes = ['/mob-notifications', '/mob-profile', '/mob-profile/yes']; // Routes where the header should be hidden
  const shouldShowHeader = !hideHeaderRoutes.includes(location.pathname);
  const subscribeModalRef = useRef(null);
  const loginUrl = getLoginUrl(location);
  const headerStyle = {
    display: shouldShowHeader ? 'block' : 'none'
  }


  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [noNotifications, setNoNotifications] = useState(false);
  const [profile, setProfile] = useState(null);
  const sidebarRef = useRef(null);
  const [showUnsubModal, setShowUnsubModal] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleUnsubModal = () => {
    setShowUnsubModal(!showUnsubModal);
  }
  const toggleSubModal = () => {
    setShowSubModal(!showSubModal);
  }

  useEffect(() => {
    async function fetchReqData() {
      try {
        let getProfileData = JSON.parse(localStorage.getItem("profileData"));
        if (!getProfileData) {
          getProfileData = await requestGetHTTP('/v1/user/profile');
        }
        setProfile(getProfileData);

        const getNotifications = await requestPostHTTP('/v1/user/notifications');
        if (getNotifications.notifications.length > 0) {
          let finalNotifications = getNotifications.notifications.filter(notification => notification.seen === 0);
          if (finalNotifications.length > 0) {
            setNotifications(finalNotifications);
          } else {
            setNoNotifications(true);
          }
        } else {
          setNoNotifications(true);
        }
      } catch (error) {
        console.error('Fetch Notifications ', error);
      }
    }

    if (isLogin()) {
      fetchReqData();
    }
    props.openSubscribedModal(subscribeModalRef);

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <section>
        <div className="header" style={headerStyle}>
          {/* Desktop Header */}
          <nav className="navbar navbar-expand-sm navbar-dark">
            <div className="container-fluid" style={{ flexWrap: 'nowrap' }}>
              <a ref={sidebarRef} href="#" onClick={toggleSidebar} className="navbar-toggler open-sidebar">
                <img src={menuIcon} alt="menu-icon" />
              </a>

              <Link className="navbar-desktop-img" to="/">
                <img src={logo} alt="logo" className="header-logo" />
              </Link>

              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav mobile-nav">
                  <li className="nav-item">
                    <Link className={location.pathname === '/challenges' ? 'nav-link game-icon active' : 'nav-link game-icon'} to="/challenges">
                      <img src={gameIcon} alt="game-icon" />
                      <span>Challenges</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={location.pathname === '/rewards' ? 'nav-link active' : 'nav-link'} to="/rewards">
                      <img src={starIcon} alt="star-icon" />
                      <span>Rewards</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={location.pathname === '/tournaments' ? 'nav-link active' : 'nav-link'} to="/tournaments">
                      <img src={tournamentIcon} alt="tournament-icon" />
                      <span>Tournaments</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={location.pathname === '/leaderboard' ? 'nav-link active' : 'nav-link'} to="/leaderboard">
                      <img src={leaderboardIcon} alt="tournament-icon" width={30} />
                      <span>Leaderboard</span>
                    </Link>
                  </li>
                  {shouldRenderYouTubeVideos() && (
                    <li className="nav-item">
                      <Link className={location.pathname === '/tournament-highlights' ? 'nav-link active' : 'nav-link'} to="/tournament-highlights">
                        <img src={youtubeIcon} alt="tournament-icon" width={30} />
                        <span>Highlights</span>
                      </Link>
                    </li>
                  )}
                  {/* {isLogin() && (
                    <li className="nav-item">
                      <Link className={location.pathname === '/completed-task' ? 'nav-link active' : 'nav-link'} to="/completed-task">
                        <img src={completeTaskIcon} alt="completeTask-icon" />
                        <span>Completed Tasks</span>
                      </Link>
                    </li>
                  )} */}
                </ul>
              </div>
              {isLogin() && (
                <>
                  <Link className="notification-icon-mobile" to="/mob-notifications">
                    <img src={notificationIcon} alt="notification-icon" />
                  </Link>
                  <a
                    href="#"
                    className="notification-icon-desktop"
                    data-bs-toggle="modal"
                    data-bs-target="#notificationModal"
                  >
                    <img src={notificationIcon} alt="notification-icon" />
                  </a>
                  <div className="profile">
                    <Link to="/profile">
                      <img
                        className="desktop-header-profile-img"
                        src={(profile && profile.profileImage) ? profile.profileImage : profileImg}
                        alt="profile-img"
                        width="50px"
                        height="50px"
                      />
                    </Link>
                  </div>
                </>
              )}
              {!isLogin() && (
                <a href={loginUrl} className="desktop-header-button">
                  Subscribe
                </a>
              )}
            </div>
          </nav>
          {/* Mobile Sidebar/Header */}
          <div className="mobile-header">
            <div className="container theme-container">
              <div className={`menu-sidebar ${isOpen ? 'show' : ''}`}>
                <div className="close">
                  <img onClick={toggleSidebar} src={crossIcon} alt="cross-icon" />
                </div>
                <div className="welcome d-flex align-items-center"></div>
                {isLogin() && (
                  <div className="container">
                    <div className="profile-imge-wrapper">
                      <Link to="/mob-profile">
                        <img
                          className="user-profile-image-sidebar"
                          src={(profile && profile.profileImage) ? profile.profileImage : profileImg}
                          alt="profile-img"
                          width="50px"
                        />
                      </Link>
                    </div>
                    <div className="profile-text-sidebar">
                      <p className="profile-username-sidebar">{profile && profile.username}</p>
                      <span className="profile-userid-sidebar"><span className="title_user_id">User ID :</span> {profile && profile.phone}</span>
                    </div>
                    <div className="profile-edit-sidebar">
                      <Link to="/mob-profile/yes">
                        <img src={editIcon} alt="edit-icons" />
                      </Link>
                    </div>
                  </div>
                )}
                <div className="mobileMenuLinks">
                  <ul>
                    <li>
                      <Link className={location.pathname === '/' ? 'menue-active' : ''} to="/">
                        <img src={homeIcon} alt="Home-icon" />
                        <span> Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link className={location.pathname === '/challenges' ? 'menue-active' : ''} to="/challenges">
                        <img src={gameIcon} alt="game-icon" />
                        <span> Challenges</span>
                      </Link>
                    </li>
                    <li>
                      <Link className={location.pathname === '/rewards' ? 'menue-active' : ''} to="/rewards">
                        <img src={starIcon} alt="star-icon" />
                        <span> Rewards</span>
                      </Link>
                    </li>
                    <li>
                      <Link className={location.pathname === '/tournaments' ? 'menue-active' : ''} to="/tournaments">
                        <img src={tournamentIcon} alt="tournament-icon" />
                        <span> Tournaments</span>
                      </Link>
                    </li>
                    <li>
                      <Link className={location.pathname === '/leaderboard' ? 'menue-active' : ''} to="/leaderboard">
                        <img src={leaderboardIcon} alt="tournament-icon" width={40} style={{ marginLeft: "-8px" }} />
                        <span> Leaderboard</span>
                      </Link>
                    </li>

                    {shouldRenderYouTubeVideos() && (
                      <li>
                        <Link className={location.pathname === '/tournament-highlights' ? 'menue-active' : ''} to="/tournament-highlights">
                          <img src={youtubeIcon} alt="tournament-icon" width={40} style={{ marginLeft: "-8px" }} />
                          <span> Highlights</span>
                        </Link>
                      </li>
                    )}
                    {isLogin() && (
                      <>
                        <li>
                          <Link className={location.pathname === '/completed-task' ? 'menue-active' : ''} to="/completed-task">
                            <img src={completeTaskIcon} alt="completeTask-icon" />
                            <span> Completed Tasks</span>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ marginBottom: '70px' }} className={location.pathname === '/completed-task' ? 'menue-active' : ''} to="/" onClick={logOut} >
                            <img className="logout-icon" src={logoutIcon} alt="completeTask-icon" />
                            <span> Logout</span>
                          </Link>
                        </li>
                        <li>
                          {isLogin() ? (
                            <button
                              className="unsubscribe-button-sidebar"
                              onClick={toggleUnsubModal}
                              style={{
                                width: "80%",
                                position: "relative"
                              }}
                            >
                              Unsubscribe
                            </button>
                          ) : (
                            <button
                              ref={subscribeModalRef}
                              className="subscribe-button-sidebar"
                              onClick={toggleSubModal}
                            >
                              Subscribe
                            </button>
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Desktop Notification */}
      <div className="modal fade notification-modal" id="notificationModal" tabIndex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog notification-modal-dialog">
          <div className="modal-content notification-modal-content">
            <div className="modal-header">
              <h5 className="modal-title notification-modal-title" id="exampleModalLabel">Notifications</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <div className="close">
                  <img src={crossIcon} alt="cross-icon" />
                </div>
              </button>
            </div>
            <div className="modal-body notification-messages">
              {/* notification.url*/}
              {notifications.length > 0 && notifications.map((notification, key) => (
                <div key={key} className="notification-text">{notification.message}
                  <img src={deleteImg} className="notification-img" alt="cross-icon" />
                </div>
              ))}
              {noNotifications && (
                <div className="notification-text">No new notifications</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*Model*/}
      <div
        className={`modal subscribe-modal ${showSubModal ? "block-modal" : "hide-modal"}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subscribe-modal-dialog">
          <div className="modal-content subscribe-modal-content">
            <div className="modal-body subscribe-modal-body">
              <div className="subscribe-modal-body-back-img notification-back-img">
                <img className="subscribe-modal-icon" src={bellimg} />
              </div>
              <h6 className="subscribe-info-heading-modal">Subscribe Now</h6>
              <p className="subscribe-heading-modal">Subscribe now to submit entries, play tournaments and win
                unlimited rewards</p>
              <Link className="subscribe-btn-modal" to={loginUrl}>
                Subscribe
              </Link>
              <Link
                onClick={toggleSubModal}
                className="subscribe-btn-modal close"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*Model*/}
      <div
        className={`modal subscribe-modal ${showUnsubModal ? "block-modal" : "hide-modal"}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subscribe-modal-dialog">
          <div className="modal-content subscribe-modal-content">
            <div className="modal-body subscribe-modal-body">
              <div className="subscribe-modal-body-back-img notification-back-img">
                <img className="subscribe-modal-icon" src={unSubscribed} />
              </div>
              <h6 className="subscribe-info-heading-modal">Are you sure?</h6>
              <p className="subscribe-heading-modal">Are you sure you want to unsubscribe from the Jazz ESL?</p>
              <Link className="unsubscribe-btn-modal" to="/un-subscribed">
                Unsubscribe
              </Link>
              <Link onClick={toggleUnsubModal} className="subscribe-btn-modal close">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Header;
