import React from "react";
import { useNavigate } from "react-router-dom";
import styles from '../../../assets/css_modules/redeem/redeemForm.module.css';
import { useEffect } from 'react';
function ThankYou() {
    let navigate = useNavigate();
    const navigateTo = () => navigate("/")
    useEffect(() => {
        document.title = "Thank You | ESL";
    }, [])
    return (
        <>
            <main style={{ height: "80vh", display: "flex", "justifyContent": "center", background: "#f1ba1f" }}>
                <section>
                    <div className="mt-4" style={{ marginLeft: '20px', marginRight: '20px' }} id="redeem-form-submit">
                        <p className="text-black text-center text-black fs-24">Thank You!</p>
                        <div className="text-center">
                            <p className="text-black text-center outfitRegular fs-20 mt-15 line-height-12">We have received your redemption request, and our team will contact you within 24 hours. <br /> <br /> Please note that our business hours are Monday to Friday, from 9:00 AM to 10:00 PM. <br /> Any redemption requests submitted after 10:00 PM on Friday will be processed on the following Monday.</p>
                            <p className="text-black text-center outfitRegular fs-20 line-height-12">Happy Gaming!</p>
                            <button className={styles.redeembtn} onClick={navigateTo}>Home</button>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default React.memo(ThankYou);